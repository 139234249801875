<template>
  <div class="summary-reviews product__container">
    <div class="summary-specifications">
      <h2 class="summary-specifications__title">
        {{ _T("@Specifications") }}
      </h2>
      <Specifications :specifications="getSpecifications()" />
      <ProductLink
        :product="product"
        :tab="'product_specifications'"
        class="go-to"
      >
        <span>{{ _T("@Product all specifications") }}</span>
        <img
          src="@/assets/icons/nav-arrow-down.svg"
          alt="arrow down"
          loading="lazy"
        />
      </ProductLink>
    </div>
    <div class="summary-reviews__container">
      <ReviewsPageHeader :product="product" :is-primary-headline="false" />
      <template v-if="reviews.data?.length > 0">
        <Reviews :reviews="reviews.data" />
        <ProductLink
          :product="product"
          :tab="'product_reviews'"
          class="summary-reviews__btn-more"
        >
          <span>{{ _T("@Product all reviews") }}</span>
          <img
            src="~~/assets/icons/reviews-arrow.svg"
            alt="arrow"
            loading="lazy"
          />
        </ProductLink>
      </template>
    </div>
    <SeenProductCarousel />
  </div>
</template>

<script setup>
import Specifications from "~/modules/product/ui/components/Specifications.vue";
import { Product } from "~/models/product.model";
import SeenProductCarousel from "~/modules/shared/sliders/productCarousels/SeenProductCarousel.vue";
import ReviewsPageHeader from "~/modules/product/ui/components/ReviewsPageHeader.vue";
import Reviews from "~/modules/product/ui/components/Reviews.vue";
import { Specification } from "~/models/specification.model";

const props = defineProps({
  product: { type: Product, required: true },
  reviews: { type: Object, required: true, default: () => {} },
});

const getSpecifications = () => {
  return props.product.specifications.slice(0, 1).map(({ group, items }) => {
    return new Specification({
      group,
      items: items.slice(0, 5),
    });
  });
};
</script>
